import React, { useState } from 'react'
import MenuBar from './MenuBar'
import { Icon } from '../Icon/Icon'
import {
  TypeNavigationMenu,
  TypeResourceSet,
  TypePhoneBlock,
  TypePage,
} from '../../types/contentful'
import { isContentfulType } from '../../lib/contentful'
import MenuGroup from './MenuGroup'
import Link from 'next/link'
import { useRouter } from 'next/router'
import useScroll from '../../helpers/hooks/useScroll'
import { themes, navBarStyles, navBarLogoPosition } from '../../types/types'
import SVG from 'react-inlinesvg'
import classNames from 'classnames'
import { isAcademyexApp } from '../../lib/detectEnvironment'
import { TopBanner } from '../Course/CourseV2Banners'
import { Document } from '@contentful/rich-text-types'
import PhoneBlock from '../Phoneblock/PhoneBlock'
import { useUser } from '@auth0/nextjs-auth0/client'
import { generateLoginURL } from '../../lib/utils'

const LinkWrapper = ({ children, href }) => {
  if (!href) {
    return <>{children}</>
  }

  if (href.includes('https')) {
    return <a href={href}>{children}</a>
  } else {
    return <Link href={href}>{children}</Link>
  }
}

type MenuProps = {
  //values
  menu?: TypeResourceSet<'WITHOUT_UNRESOLVABLE_LINKS', string>
  // colors
  lightColor?: string
  darkColor?: string
  theme?: TypePage<'WITHOUT_UNRESOLVABLE_LINKS', string>['fields']['navigationTheme'] | 'AEX Splash'
  hoverColor?: string
  bannerProps?: {
    showBanner: boolean
    setShowTopBanner: (value: boolean) => void
    topBannerText: Document
  }
  hideBurgerMenu?: boolean
  stationary?: boolean
  logoOnClick?: () => void
}

const Menu = ({
  menu,
  menu: {
    fields: {
      hoverColor,
      navBarStyle = navBarStyles.HAMBURGER,
      lightColor,
      darkColor,
      logoPosition = navBarLogoPosition.CENTER,
      resources = [],
      navbarButtons,
      darkLargeLogo,
      lightLargeLogo,
      darkSmallLogo,
      lightSmallLogo,
      sideMenuTopIcon,
      // type,
    },
  },
  theme = themes.LIGHT,
  bannerProps,
  hideBurgerMenu,
  stationary,
  logoOnClick,
}: MenuProps) => {
  const router = useRouter()
  const [open, setOpen] = useState(false)
  const handleMenuClick = () => setOpen(open => !open)
  const activeSlug = router.asPath
  const { scrolled, scrolledToTop } = useScroll()

  const { showBanner, setShowTopBanner, topBannerText } = bannerProps || {}

  const { user } = useUser()

  const isHome = router.pathname === '/' || router.asPath === '/b'
  const buttonIndex = user ? (navbarButtons.length === 2 ? 1 : 0) : 0
  const logoSlug = isHome ? undefined : process.env.NEXT_PUBLIC_BASE_URL

  const cssVars = {
    '--dark-navbar-bg': `${darkColor}`,
    '--light-navbar-bg': `${lightColor}`,
    transitionProperty: 'background-color',
    transitionDuration: '300ms',
  } as React.CSSProperties

  let transparentLargeLogo
  let transparentSmallLogo
  let navbarBgColor
  let largeLogo
  let smallLogo

  const lmsURL = process.env.NEXT_PUBLIC_LMS_URL
  const redirectURL = `${process.env.NEXT_PUBLIC_BASE_URL}${router.asPath}`
  const loginUrl = generateLoginURL(lmsURL, redirectURL)

  const solid = 'bg-light-navbar dark:bg-dark-navbar'
  const transparent = scrolled && !scrolledToTop ? 'bg-light-navbar' : 'transparent'

  const darkMode =
    theme === themes.DARK || theme === themes.AEX_SPLASH
      ? 'dark'
      : theme === themes.TRANSPARENT_LIGHT && scrolledToTop
      ? 'dark'
      : ''

  switch (theme) {
    case themes.DARK:
      navbarBgColor = solid
      largeLogo = `https:${darkLargeLogo?.fields?.file?.url}`
      smallLogo = `https:${darkSmallLogo?.fields?.file?.url}`
      break

    case themes.LIGHT:
      navbarBgColor = solid
      largeLogo = `https:${lightLargeLogo?.fields?.file?.url}`
      smallLogo = `https:${lightSmallLogo?.fields?.file?.url}`
      break

    case themes.TRANSPARENT_LIGHT:
      navbarBgColor = transparent
      largeLogo = `https:${lightLargeLogo?.fields?.file?.url}`
      smallLogo = `https:${lightSmallLogo?.fields?.file?.url}`
      transparentLargeLogo = `https:${darkLargeLogo?.fields?.file?.url}`
      transparentSmallLogo = `https:${darkSmallLogo?.fields?.file?.url}`
      break

    case themes.TRANSPARENT_DARK:
      navbarBgColor = transparent
      largeLogo = `https:${lightLargeLogo?.fields?.file?.url}`
      smallLogo = `https:${lightSmallLogo?.fields?.file?.url}`
      break

    case themes.AEX_SPLASH:
      navbarBgColor = scrolledToTop ? transparent : solid
      largeLogo = `https:${darkLargeLogo?.fields?.file?.url}`
      smallLogo = `https:${lightSmallLogo?.fields?.file?.url}`
      break

    default:
      navbarBgColor = solid
      largeLogo = `https:${lightLargeLogo?.fields?.file?.url}`
      smallLogo = `https:${lightSmallLogo?.fields?.file?.url}`
      break
  }

  return (
    <>
      <div
        className={classNames(
          darkMode,
          'left-0 right-0 top-0 z-[200] overflow-hidden',
          stationary ? 'absolute' : 'fixed'
        )}
        style={cssVars}
      >
        <div className={navbarBgColor}>
          <div className={`mx-auto px-4 sm:px-6`}>
            {showBanner && (
              <TopBanner topBannerText={topBannerText} setShowTopBanner={setShowTopBanner} />
            )}
            <div className="flex items-center py-7 justify-start md:space-x-10">
              <div className="flex flex-1 justify-start items-center gap-6">
                {!hideBurgerMenu &&
                  navBarStyle !== navBarStyles.NOSHOW &&
                  menu?.fields?.resources?.length > 0 && (
                    <button
                      onClick={handleMenuClick}
                      className={classNames(
                        'flex justify-start items-center text-sm uppercase dark:text-primary-ui-white',
                        navBarStyle !== navBarStyles.HAMBURGER && 'md:hidden'
                      )}
                    >
                      <Icon
                        name={'Hamburger'}
                        className={`cursor-pointer text-secondary-ui-900 dark:text-primary-ui-white hover:text-black mr-1`}
                      />
                      Menu
                    </button>
                  )}
                <div
                  className={classNames(
                    'flex flex-shrink-0',
                    navBarStyle === navBarStyles.NOSHOW ? 'sm:hidden' : 'hidden',
                    logoPosition === 'left' && 'sm:!flex'
                  )}
                >
                  <LinkWrapper
                    href={
                      logoOnClick
                        ? ''
                        : !isAcademyexApp()
                        ? process.env.NEXT_PUBLIC_BASE_URL
                        : isHome
                        ? '/browse/courses'
                        : process.env.NEXT_PUBLIC_BASE_URL
                    }
                  >
                    {/* eslint-disable-next-line @next/next/no-img-element */}
                    <img
                      src={
                        theme === themes.TRANSPARENT_LIGHT && scrolledToTop
                          ? transparentLargeLogo
                          : largeLogo
                      }
                      alt={darkLargeLogo?.fields?.title}
                      className="max-h-8 w-auto cursor-pointer"
                      onClick={logoOnClick}
                    />
                  </LinkWrapper>
                </div>
                <div
                  className={`hidden ${
                    navBarStyle === navBarStyles.INLINE ? 'md:flex' : 'hidden'
                  } justify-start flex-column flex-1 ml-8 md:dark:text-primary-ui-white`}
                >
                  {resources?.map(menuItem => {
                    if (
                      isContentfulType<TypeNavigationMenu<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
                        menuItem,
                        'navigationMenu'
                      ) &&
                      menuItem?.fields?.title === 'Top navigation'
                    ) {
                      return (
                        <MenuGroup
                          key={menuItem.sys.id}
                          menu={menuItem}
                          activeSlug={activeSlug}
                          hoverColor={hoverColor}
                          type="navbar"
                        />
                      )
                    }
                  })}
                </div>
              </div>
              <div
                className={`hidden ${
                  logoPosition === navBarLogoPosition.CENTER ? 'sm:flex' : 'hidden'
                }`}
              >
                <LinkWrapper href={logoOnClick || !logoSlug ? '' : logoSlug}>
                  {darkLargeLogo?.fields?.file.contentType === 'image/svg+xml' ? (
                    <div className={`w-auto max-h-10 md:h-10`}>
                      <SVG
                        className="[&>*]:fill-[--dark-navbar-bg] cursor-pointer"
                        src={`https:${darkLargeLogo?.fields?.file.url}`}
                        width="100%"
                        height="100%"
                        style={cssVars}
                        onClick={logoOnClick}
                      />
                    </div>
                  ) : (
                    <>
                      {/* eslint-disable-next-line @next/next/no-img-element */}
                      <img
                        src={
                          theme === themes.TRANSPARENT_LIGHT && scrolledToTop
                            ? transparentLargeLogo
                            : largeLogo
                        }
                        alt={darkLargeLogo?.fields?.title}
                        className="max-h-8 w-auto cursor-pointer"
                        onClick={logoOnClick}
                      />
                    </>
                  )}
                </LinkWrapper>
              </div>
              <div
                className={classNames(
                  'flex sm:hidden space-x-10 md:dark:text-primary-ui-white',
                  navBarStyle === navBarStyles.NOSHOW && 'hidden'
                )}
              >
                <LinkWrapper
                  href={
                    logoOnClick
                      ? ''
                      : !isAcademyexApp()
                      ? process.env.NEXT_PUBLIC_BASE_URL
                      : isHome
                      ? '/browse/courses'
                      : process.env.NEXT_PUBLIC_BASE_URL
                  }
                >
                  {/* eslint-disable-next-line @next/next/no-img-element */}
                  <img
                    src={
                      theme === themes.TRANSPARENT_LIGHT && scrolledToTop
                        ? transparentSmallLogo
                        : smallLogo
                    }
                    alt={darkLargeLogo?.fields?.title}
                    className="max-h-8 w-auto cursor-pointer"
                    onClick={logoOnClick}
                  />
                </LinkWrapper>
              </div>
              <div className="flex flex-1 md:flex-0 lg:flex-1 justify-end items-center gap-6">
                {user && (
                  <>
                    <button
                      onClick={() => router.push(lmsURL)}
                      className="text-white bg-gradient-to-r from-purple-500 to-pink-500 hover:bg-gradient-to-l focus:ring-4 focus:outline-none focus:ring-purple-200 dark:focus:ring-purple-800 font-medium rounded-full text-sm px-5 py-2 text-center"
                    >
                      My Learning
                    </button>
                  </>
                )}
                {!user && (
                  <button
                    onClick={() => router.push(loginUrl)}
                    className="text-white bg-gradient-to-r from-purple-500 to-pink-500 hover:bg-gradient-to-l focus:ring-4 focus:outline-none focus:ring-purple-200 dark:focus:ring-purple-800 font-medium rounded-full text-sm px-5 py-2 text-center"
                  >
                    Login
                  </button>
                )}
                {isContentfulType<TypePhoneBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
                  navbarButtons[buttonIndex],
                  'phoneBlock'
                ) && (
                  <PhoneBlock
                    phoneNumber={
                      (
                        navbarButtons[buttonIndex] as TypePhoneBlock<
                          'WITHOUT_UNRESOLVABLE_LINKS',
                          string
                        >
                      )?.fields?.phoneNumber
                    }
                    id={navbarButtons[buttonIndex]?.sys?.id}
                    color={
                      (
                        navbarButtons[buttonIndex] as TypePhoneBlock<
                          'WITHOUT_UNRESOLVABLE_LINKS',
                          string
                        >
                      )?.fields?.color
                    }
                    mobileColor={
                      (
                        navbarButtons[buttonIndex] as TypePhoneBlock<
                          'WITHOUT_UNRESOLVABLE_LINKS',
                          string
                        >
                      )?.fields?.mobilecolor
                    }
                    callable={
                      (
                        navbarButtons[buttonIndex] as TypePhoneBlock<
                          'WITHOUT_UNRESOLVABLE_LINKS',
                          string
                        >
                      )?.fields?.callable
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {navBarStyle !== navBarStyles.NOSHOW && menu?.fields?.resources?.length > 0 && (
        <div style={cssVars} className={theme === themes.DARK ? 'dark' : undefined}>
          <MenuBar
            menu={menu}
            open={open}
            onClose={() => setOpen(false)}
            activeSlug={activeSlug}
            hoverColor={hoverColor}
            sideMenuTopIcon={sideMenuTopIcon}
          />
        </div>
      )}
    </>
  )
}

export default Menu
